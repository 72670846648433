<template>
  <ui5-message-strip hide-close-button :data-cypress="cypressId" v-if="visible" :design="stripType === 'Error' ? 'Negative' : 'Positive'">
    {{ stripText }}
  </ui5-message-strip>
</template>

<script>
export default {
    name: "MessageStrip",
    props: {
        stripType: {
            type: String,
            default: "None",
        },
        stripText: {
            type: String,
            default: "",
        },
        visible: {
            type: Boolean,
            default: false,
        },
        cypressId: {
            type: String,
            required: false,
            default: "",
        },
    },
};
</script>

<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div id="loadingSpinner" class="loader" />
  <section class="columns">
    <div class="feed">
      <router-link to="/" class="logo_light"><img src="./assets/logo_light.svg" alt="myleo" /></router-link>
      <div class="slider">
        <div data-am-fadeshow="next-prev-navigation autoplay">
          <input type="radio" name="css-fadeshow" id="slide-1" />
          <input type="radio" name="css-fadeshow" id="slide-2" />
          <div class="fs-slides">
            <div class="fs-slide">
              <img src="./assets/feed_slot.png" alt="Slot" />
              <ui5-title level="H2">{{ $t("feed.headline1") }}</ui5-title>
              <p>{{ $t("feed.text1") }}</p>
              <div class="button">
                <ui5-button design="Default" @click="openContact($t('feed.url'))">
                  {{ $t("feed.contact") }}
                </ui5-button>
              </div>
            </div>
            <div class="fs-slide">
              <img src="./assets/feed_tnt.png" alt="TnT" />
              <ui5-title level="H2">{{ $t("feed.headline2") }}</ui5-title>
              <p>{{ $t("feed.text2") }}</p>
              <div class="button">
                <ui5-button design="Default" @click="openContact($t('feed.url'))">
                  {{ $t("feed.contact") }}
                </ui5-button>
              </div>
            </div>
          </div>
          <div class="fs-quick-nav">
            <label class="fs-quick-btn" for="slide-1" />
            <label class="fs-quick-btn" for="slide-2" />
          </div>
          <div class="fs-prev-nav">
            <label class="fs-prev-btn" for="slide-1" />
            <label class="fs-prev-btn" for="slide-2" />
          </div>
          <div class="fs-next-nav">
            <label class="fs-next-btn" for="slide-1" />
            <label class="fs-next-btn" for="slide-2" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <router-link to="/" class="logo_dark"><img src="./assets/logo_dark.svg" alt="myleo" /></router-link>
      <LanguageSwitcher cypressId="languageSwitcher" class="language" />
      <router-view class="routerView" data-cypress="navigation" />
      <div class="navigation">
        <router-link data-cypress="navigation_login" :to="('/' + this.sAlias)" v-show="$route.name !== 'Login'">{{ $t("home") }}</router-link>
        <router-link data-cypress="navigation_policy" to="/policy">{{ $t("policy.policy") }}</router-link>
        <router-link data-cypress="navigation_imprint" to="/imprint">{{ $t("imprint.imprint") }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import LanguageSwitcher from "./components/LanguageSwitcher.vue";

export default {
    name: "App",
    components: {
        LanguageSwitcher,
    },
    methods: {
        openContact(sURL) {
            window.open(sURL, "_blank");
        },
    },
    beforeUnmount() {
        localStorage.setItem("sAlias", "");
    },
    mounted() {
        window.addEventListener("load", () => {
            document.querySelector(".loader").style.visibility = "hidden";
            document.querySelector(".columns").style.visibility = "visible";
            setTimeout(() => {
                if (document.querySelector(".wrapper").offsetHeight > (window.innerHeight - 250)) {
                    document.querySelector(".wrapper").classList.add("relative");
                }
            }, 100);
        });
        window.addEventListener("resize", () => {
            if (document.querySelector(".wrapper").offsetHeight > (window.innerHeight - 250)) {
                document.querySelector(".wrapper").classList.add("relative");
            } else {
                document.querySelector(".wrapper").classList.remove("relative");
            }
        });
        const i18nLanguages = this.$i18n.availableLocales;
        const browserLanguage = (navigator.language || navigator.userLanguage).substr(0, 2);
        if (i18nLanguages.includes(browserLanguage)) {
            this.$i18n.locale = browserLanguage;
        } else {
            this.$i18n.locale = "en";
        }
        if ((localStorage.getItem("sAlias") !== "") && (localStorage.getItem("sAlias") !== null)) {
            this.sAlias = localStorage.getItem("sAlias");
        } else {
            this.sAlias = "";
        }
    },
};
</script>

<style lang="scss">
@use "styles";
</style>

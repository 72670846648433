<template>
  <ui5-label :data-cypress="cypressIdLabel" for="loginEmail">{{ $t("login.email") }}</ui5-label>
  <ui5-input
    :data-cypress="cypressIdInput"
    :value-state="emailValidation.valueState"
    type="Email"
    name="email"
    id="loginEmail"
    maxlength="125"
    :placeholder="$t('login.email')"
    :value="email"
    @input="handleEmailValidation">
    <div slot="valueStateMessage">{{ emailValidation.valueStateMessage }}</div>
  </ui5-input>
</template>

<script>
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport";
import "@ui5/webcomponents/dist/Label";

export default {
    name: "EmailInput",
    emits: ["input", "validate"],
    data() {
        return {
            emailValidation: {
                valueState: "None",
                valueStateMessage: "",
            },
        };
    },
    props: {
        email: {
            type: String,
            required: true,
        },
        cypressIdInput: {
            type: String,
            required: false,
            default: "",
        },
        cypressIdLabel: {
            type: String,
            required: false,
            default: "",
        },
    },
    mounted() {
        const inputMail = document.getElementById("loginEmail");
        inputMail?.addEventListener("keypress", (oEvent) => {
            const sKey = oEvent.keyCode;
            if (sKey === 32) {
                oEvent.preventDefault();
            }
        });
    },
    methods: {
        handleEmailValidation(oEvent) {
            const sMail = oEvent.target.value.toLowerCase();
            this.emailValidation.valueState = "None";
            this.emailValidation.valueStateMessage = "";
            if (sMail === "") {
                this.emailValidation.valueState = "None";
                this.emailValidation.valueStateMessage = "";
            } else {
                const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
                const emailRegex = /^[\S._%+-]+@[\S.-]+\.[A-Za-z]{2,}$/gm;
                if (emojiRegex.test(sMail)) {
                    this.emailValidation.valueState = "Error";
                    this.emailValidation.valueStateMessage = this.$t("login.emailValidation.globalErrorEmojisNotAllowed");
                } else if (!emailRegex.test(sMail) || sMail.split("@").length > 2) {
                    this.emailValidation.valueState = "Error";
                    this.emailValidation.valueStateMessage = this.$t("login.emailValidation.emailErrorNotValid");
                } else if (sMail.length > 126) {
                    this.emailValidation.valueState = "Error";
                    this.emailValidation.valueStateMessage = this.$t("login.emailValidation.emailErrorTooLong");
                } else if (sMail.split("@")[1] && sMail.split("@")[1].split(".").length > 2) {
                    this.emailValidation.valueState = "Information";
                    this.emailValidation.valueStateMessage = this.$t("login.emailValidation.emailErrorNotTypical");
                }
            }
            this.$emit("input", oEvent.target.value);
            this.$emit("validate", this.emailValidation.valueState);
        },
    },
};
</script>

<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div id="login" class="wrapper">
    <div class="form">
      <div class="center">
        <ui5-title data-cypress="customWelcomeText" level="H2" v-if="customLogin.customWelcomeText">{{ customLogin.customWelcomeText }}</ui5-title>
        <ui5-title data-cypress="customCompanyName" level="H4" v-if="customLogin.customCompanyName">{{ customLogin.customCompanyName }}</ui5-title>
        <img data-cypress="customLoginImage" :src="customLogin.customImage" v-if="customLogin.customImage" alt="Logo" class="customLogo" />
      </div>
      <ui5-title level="H2">{{ $t("login.login") }}</ui5-title>
      <div v-if="!customLogin.disableEmailPasswordAuthenticationForNonAdmins">
        <form @submit.prevent="handleLogin" @keydown.enter="handleLogin">
          <div class="inputWrapper">
            <input data-cypress="login_hiddenEmail" tabindex="-1" class="hiddenInput username" id="username" name="username" type="Email" autocomplete="username" :placeholder="$t('login.email')" v-model="form.email" />
            <EmailInput cypressIdInput="login_email" cypressIdLabel="login-emailLabel" :email="form.email" @validate="handleValidateEmail" @input="handleSetEmail" />
          </div>
          <div class="inputWrapper">
            <ui5-label for="loginPassword">{{ $t("login.password") }}</ui5-label>
            <input data-cypress="login_hiddenPassword" tabindex="-1" class="hiddenInput password" id="password" name="password" type="Password" autocomplete="current-password" :placeholder="$t('login.password')" v-model="form.password" />
            <ui5-input data-cypress="login_password" type="Password" name="password" id="loginPassword" :placeholder="$t('login.password')" :value="form.password" @input="handleSetPassword">
              <ui5-icon id="showPWIcon" name="show" slot="icon" />
            </ui5-input>
          </div>
          <div v-if="twoFA.visible">
            <ui5-label for="twoFAKey" required show-colon>{{ $t("login.twoFAKey") }}</ui5-label>
            <ui5-input data-cypress="login_2fa" required type="Number" id="twoFAKey" :placeholder="$t('login.twoFAKey')" :value="twoFA.value" @input="handleSet2FaValue" />
          </div>
          <div class="forgot">
            <router-link to="/forgotPassword">{{ $t("login.forgotPassword") }}</router-link>
          </div>
          <div class="button">
            <ui5-busy-indicator id="busyIndicator" size="Medium" />
            <ui5-button data-cypress="login_button" design="Default" @click="handleLogin">
              {{ $t("login.login") }}
            </ui5-button>
          </div>
        </form>
        <MessageStrip cypressId="loginFailed2Fa_Needed" stripType="Warning" :visible="showLogin2FaNeeded" :stripText="$t('login.errors.login2FaNeeded')" />
        <MessageStrip cypressId="loginFailed2Fa_Error" stripType="Error" :visible="showLogin2FaFailed" :stripText="$t('login.errors.login2FaFailed')" />
      </div>
      <MessageStrip cypressId="login_error" stripType="Error" :visible="showError" :stripText="$t('login.error')" />
      <div v-if="customLogin.disableEmailPasswordAuthenticationForNonAdmins ? false : customLogin.hasAvailableLoginMethods">
        <ui5-title level="H2" class="center">{{ $t("login.or") }}</ui5-title>
      </div>
      <div class="button" v-if="customLogin.hasOpenID" :disabled="!customLogin.hasOpenID">
        <ui5-button data-cypress="login_method_oid_button" id="buttonOID" design="Default" @click="handleLoginOID">
          {{ $t("login.continueWith") }} {{ customLogin.nameOpenID }} (OpenID)
        </ui5-button>
      </div>
      <div class="button" v-if="customLogin.hasSAML" :disabled="!customLogin.hasSAML">
        <ui5-button data-cypress="login_method_saml_button" id="buttonSAML" design="Default" @click="handleLoginSAML">
          {{ $t("login.continueWith") }} {{ customLogin.nameSAML }} (SAML)
        </ui5-button>
      </div>
      <MessageStrip cypressId="loginOpenId_error" stripType="Error" :visible="showErrorOpenId" :stripText="$t('login.errors.errorOpenId')" />
      <MessageStrip cypressId="loginFailedOpenId_error" stripType="Error" :visible="showLoginFailedOpenIdError" :stripText="$t('login.errors.loginFailedOpenIdError')" />
      <MessageStrip cypressId="loginSAML_error" stripType="Error" :visible="showErrorSAML" :stripText="$t('login.errors.errorSAML')" />
      <MessageStrip cypressId="loginFailedSAML_error" stripType="Error" :visible="showLoginFailedSAMLError" :stripText="$t('login.errors.loginFailedSAMLError')" />
      <MessageStrip cypressId="loginPolicyDeclinedError" stripType="Error" :visible="showPolicyDeclinedError" :stripText="$t('login.errors.policyDeclinedError')" />
      <div class="demo" v-if="customLogin.hasAvailableLoginMethods ? false : true">
        <p>{{ $t("login.noClientYet") }}<br><ui5-link @click="openContact($t('feed.url'))" class="contact">{{ $t("login.btnDemo") }}</ui5-link></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/MessageStrip";
import "@ui5/webcomponents/dist/Title";
// eslint-disable-next-line import/extensions
import "@ui5/webcomponents-icons/dist/show.js";
// eslint-disable-next-line import/extensions
import "@ui5/webcomponents-icons/dist/hide.js";

import MessageStrip from "../components/MessageStrip.vue";
import EmailInput from "../components/EmailInput.vue";

export default {
    name: "Login",
    components: {
        MessageStrip,
        EmailInput,
    },
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            emailValueState: "None",
            showError: false,
            customLogin: {
                companyId: "",
                customCompanyName: "",
                customImage: "",
                customWelcomeText: "",
                availableLoginMethods: [],
                hasAvailableLoginMethods: false,
                hasOpenID: false,
                nameOpenID: "",
                hasSAML: false,
                nameSAML: "",
                disableEmailPasswordAuthenticationForNonAdmins: false,
            },
            twoFA: {
                value: "",
                visible: false,
                isRequested: false,
            },
            sURLString: "",
            showErrorInvalidCustomPage: false,
            showLoginFailedOpenIdError: false,
            showLoginFailedSAMLError: false,
            showErrorOpenId: false,
            howErrorSAML: false,
            showPolicyDeclinedError: false,
            errorReason: "LOGIN_FAILED",
            showLogin2FaNeeded: false,
            showLogin2FaFailed: false,
        };
    },
    created() {
        this.sURLString = this.$route.path.substring(1);
        if (this.sURLString) {
            const sAlias = this.sURLString.indexOf("?") > -1 ? this.sURLString.split("?")[0] : this.sURLString;
            let sLoginAlias = "";
            if ((sAlias !== "") && (sAlias !== null) && (sAlias !== "changePassword") && (sAlias !== "changeEmail") && (sAlias !== "setInitialPassword") && (sAlias !== "register")) {
                localStorage.setItem("sAlias", sAlias);
                sLoginAlias = sAlias;
            }
            fetch(`${this.$apiGateway}/master/company/unsecure/login/${sLoginAlias}`).then((response) => response.json()).then((data) => {
                if (data.statusCode !== 400) {
                    localStorage.setItem("leoDisplayLanguage", data && data.profile && data.profile.displayLanguage ? data.profile.displayLanguage : null);
                    this.customLogin.companyId = data.companyId || "";
                    this.customLogin.customCompanyName = data.companyName || "";
                    this.customLogin.customImage = `${this.$apiGateway}${data.logo}` || "";
                    this.customLogin.customWelcomeText = data.welcomeText || "Welcome";
                    this.customLogin.availableLoginMethods = data.availableLoginMethods || [];
                    data.availableLoginMethods.forEach((loginMethod) => {
                        if (loginMethod.type === "OPEN_ID_CONNECT") {
                            this.customLogin.hasOpenID = true;
                            this.customLogin.nameOpenID = loginMethod.name;
                        } else if (loginMethod.type === "SAML2") {
                            this.customLogin.hasSAML = true;
                            this.customLogin.nameSAML = loginMethod.name;
                        }
                    });
                    this.customLogin.hasAvailableLoginMethods = data.availableLoginMethods.length > 0;
                    this.customLogin.disableEmailPasswordAuthenticationForNonAdmins = data.disableEmailPasswordAuthenticationForNonAdmins || false;
                }
            }).catch((err) => {
                this.errors = err;
                this.showErrorInvalidCustomPage = true;
            });
        }
        if (this.$route.query.oidcLoginFailed) {
            this.showLoginFailedOpenIdError = true;
        }
        if (this.$route.query.samlLoginFailed) {
            // oidcLoginFailed used instead, prepared for future changes
            this.showLoginFailedSAMLError = true;
        }
    },
    mounted() {
        setTimeout(() => document.querySelector("#showPWIcon").addEventListener("click", () => {
            const confirmPassword = document.getElementById("confirmPassword");
            if (document.getElementById("password").getAttribute("type") === "Password") {
                document.getElementById("password").setAttribute("type", "Text");
                confirmPassword?.setAttribute("type", "Text");
                document.getElementById("showPWIcon").setAttribute("name", "hide");
            } else {
                document.getElementById("password").setAttribute("type", "Password");
                confirmPassword?.setAttribute("type", "Password");
                document.getElementById("showPWIcon").setAttribute("name", "show");
            }
        }), 0);
    },
    methods: {
        async handleLogin() {
            const busyIndicator = document.getElementById("busyIndicator");
            busyIndicator.active = true;
            const oCredentials = {
                email: this.form.email,
                password: this.form.password,
            };
            if (this.twoFA.isRequested) {
                oCredentials.code = this.twoFA.value;
            }
            const options = {
                method: "POST",
                credentials: "include",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(oCredentials),
            };
            fetch(`${this.$apiGateway}/auth/jwt/signin`, options).then((response) => response.json()).then((data) => {
                this.showLogin2FaNeeded = false;
                this.showLogin2FaFailed = false;
                this.twoFA.visible = false;
                this.twoFA.isRequested = false;
                this.showError = false;
                localStorage.setItem("apiToken", null);
                if (data.statusCode === 500) {
                    this.showError = true;
                } else if (data.statusCode === 401) {
                    const b2faContsraints = Array.isArray(data.message) ? data.message.some((oMessage) => oMessage.constraints && oMessage.constraints.TOTP_CODE_MISSING) : false;
                    const b2FaWrong = Array.isArray(data.message) ? data.message.some((oMessage) => oMessage.constraints && oMessage.constraints.TOTP_CODE_WRONG) : false;
                    if (b2faContsraints) {
                        setTimeout(() => {
                            document.getElementById("twoFAKey").focus();
                        }, 0);
                        this.showError = false;
                        this.twoFA.visible = true;
                        this.twoFA.isRequested = true;
                        this.showLogin2FaNeeded = true;
                        this.showLogin2FaFailed = false;
                    } else if (b2FaWrong) {
                        this.showError = false;
                        this.twoFA.visible = true;
                        this.twoFA.isRequested = true;
                        this.showLogin2FaNeeded = false;
                        this.showLogin2FaFailed = true;
                    } else {
                        this.showError = true;
                    }
                } else {
                    localStorage.setItem("apiToken", data.access_token);
                    localStorage.setItem("leoDisplayLanguage", data.profile && data.profile.displayLanguage ? data.profile.displayLanguage : null);
                    let search = window.location.search + window.location.hash;
                    const sRedirect = this.$route.query.redirect;
                    if (sRedirect) {
                        location.href = `${this.$portalGateway}${sRedirect}`;
                    } else {
                        if (search.indexOf("#") === -1 || (this.customLogin.customCompanyName && this.customLogin.customCompanyName !== "")) {
                            search = "";
                        }
                        location.href = `${this.$portalGateway}/ui/${search}`;
                    }
                }
                busyIndicator.active = false;
            })
                .catch((error) => {
                    console.error("Error:", error);
                    this.error = error;
                    this.showError = true;
                    busyIndicator.active = false;
                });
        },
        handleLoginOID() {
            if (this.customLogin.companyId !== "") {
                history.pushState(null, null, window.location);
                window.location.replace(`${this.$apiGateway}/auth/oidc/login?companyId=${this.customLogin.companyId}`);
            } else {
                this.showErrorOpenId = true;
            }
        },
        handleLoginSAML() {
            if (this.customLogin.companyId !== "") {
                history.pushState(null, null, window.location);
                window.location.replace(`${this.$apiGateway}/auth/saml/login/${this.customLogin.companyId}`);
            } else {
                this.showErrorSAML = true;
            }
        },
        handleValidateEmail(sEmailValueState) {
            this.emailValueState = sEmailValueState;
        },
        handleSetEmail(sEmailValue) {
            this.form.email = sEmailValue;
        },
        handleSetPassword(oEvent) {
            this.form.password = oEvent.target.value;
        },
        handleSet2FaValue(oEvent) {
            this.twoFA.value = oEvent.target.value;
        },
        openContact(sURL) {
            window.open(sURL, "_blank");
        },
    },
};
</script>

<template>
  <ui5-popover id="passRequirements" data-cypress="popupPassRequirements" ref="passwordGuidelines" prevent-focus-restore="true" placement-type="Left" initialFocus="loginPassword">
    <div class="popover-content">
      <div>
        <span data-cypress="popupPassRequirements-checkIconMin8Signs" v-if="passwordGuidelinesIcons.showCheckIconMin8Signs"><ui5-icon name="accept" class="icon accept" /></span>
        <span data-cypress="popupPassRequirements-deleteIconMin8Signs" v-if="passwordGuidelinesIcons.showDeleteIconMin8Signs"><ui5-icon name="decline" class="icon decline" /></span>
        <span class="required-text">{{ $t("passwordPopoverTransKeys.minCharacter", [passwordGuidelines.minimumTotal]) }}</span>
      </div>
      <div>
        <span data-cypress="popupPassRequirements-checkIconMin1UpperCase" v-if="passwordGuidelinesIcons.showCheckIconMin1UpperCase"><ui5-icon name="accept" class="icon accept" /></span>
        <span data-cypress="popupPassRequirements-deleteIconMin1UpperCase" v-if="passwordGuidelinesIcons.showDeleteIconMin1UpperCase"><ui5-icon name="decline" class="icon decline" /></span>
        <span class="required-text">{{ $t("passwordPopoverTransKeys.minCapitalLetter", [passwordGuidelines.minimumUppercase]) }}</span>
      </div>
      <div>
        <span data-cypress="popupPassRequirements-checkIconMin1SmallCase" v-if="passwordGuidelinesIcons.showCheckIconMin1SmallCase"><ui5-icon name="accept" class="icon accept" /></span>
        <span data-cypress="popupPassRequirements-deleteIconMin1SmallCase" v-if="passwordGuidelinesIcons.showDeleteIconMin1SmallCase"><ui5-icon name="decline" class="icon decline" /></span>
        <span class="required-text">{{ $t("passwordPopoverTransKeys.minLowerCaseLetter", [passwordGuidelines.minimumLowercase]) }}</span>
      </div>
      <div>
        <span data-cypress="popupPassRequirements-checkIconMin1Number" v-if="passwordGuidelinesIcons.showCheckIconMin1Number"><ui5-icon name="accept" class="icon accept" /></span>
        <span data-cypress="popupPassRequirements-deleteIconMin1Number" v-if="passwordGuidelinesIcons.showDeleteIconMin1Number"><ui5-icon name="decline" class="icon decline" /></span>
        <span class="required-text">{{ $t("passwordPopoverTransKeys.minNumber", [passwordGuidelines.minimumNumber]) }}</span>
      </div>
      <div>
        <span data-cypress="popupPassRequirements-checkIconMin1NonWord" v-if="passwordGuidelinesIcons.showCheckIconMin1NonWord"><ui5-icon name="accept" class="icon accept" /></span>
        <span data-cypress="popupPassRequirements-deleteIconMin1NonWord" v-if="passwordGuidelinesIcons.showDeleteIconMin1NonWord"><ui5-icon name="decline" class="icon decline" /></span>
        <span class="required-text">{{ $t("passwordPopoverTransKeys.minSpecialCharacter", [passwordGuidelines.minimumSpecial]) }}</span>
      </div>
    </div>
  </ui5-popover>
</template>

<script>
import "@ui5/webcomponents/dist/Popup";
// eslint-disable-next-line import/extensions
import "@ui5/webcomponents-icons/dist/decline.js";
// eslint-disable-next-line import/extensions
import "@ui5/webcomponents-icons/dist/accept.js";

export default {
    name: "PasswordRequirementsPopup",
    props: {
        passwordGuidelinesIcons: {
            type: Object,
            required: false,
            default: null,
        },
        passwordGuidelines: {
            type: Object,
            required: false,
            default: null,
        },
    },
    mounted() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1000) {
            document.getElementById("passRequirements").setAttribute("placement-type", "Bottom");
        }
        document.body.appendChild(document.getElementById("passRequirements"));
    },
};
</script>

<template>
  <div class="wrapper">
    <div class="form">
      <ui5-title level="H2">{{ $t("login.setInitialPassword") }}</ui5-title>
      <PasswordForm
        cypressIdEmail="password_new_input_email"
        cypressIdPassword="password_new_input"
        cypressIdPasswordConfirm="password_confirm_input"
        cypressIdButton="change_password_btn"
        :buttonName="$t('login.setPassword')"
        url="setInitialPassword"
        urlPost="setInitialPassword" />
    </div>
  </div>
</template>

<script>
import PasswordForm from "../components/PasswordForm.vue";

export default {
    name: "SetInitialPassword",
    components: {
        PasswordForm,
    },
};
</script>

<template>
  <div class="text">
    <div v-if="$i18n.locale === `en`">
      <iframe title="policyEN" style="border:transparent;width:100%;height:3000px;" src="../static/en/policy.html" />
    </div>
    <div v-if="$i18n.locale === `de`">
      <iframe title="policyDE" style="border:transparent;width:100%;height:3000px;" src="../static/de/policy.html" />
    </div>
  </div>
</template>

<script>
export default {
    name: "Policy",
};
</script>

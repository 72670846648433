<template>
  <div class="wrapper">
    <div class="form">
      <ui5-title level="H2">{{ $t("login.forgotPassword") }}</ui5-title>
      <form @submit.prevent="handleRequestNewPassword">
        <EmailInput cypressIdInput="forgotPassword-emailInput" cypressIdLabel="forgotPassword-emailLabel" type="Email" name="email" id="loginEmail" maxlength="150" :email="form.email" @validate="handleValidateEmail" @input="handleSetEmail" />
        <div class="button">
          <ui5-busy-indicator id="busyIndicator" size="Medium" />
          <ui5-button data-cypress="forgotPassword-resetButton" :disabled="emailValueState === 'Error' || !isSetPasswordEnabled" design="Default" @click="handleRequestNewPassword">
            {{ $t("login.resetPassword") }}
          </ui5-button>
        </div>
      </form>
      <MessageStrip
        cypressId="forgotPassword-messageSuccess"
        stripType="Success"
        :visible="showSuccess"
        :stripText="$t('login.forgotPasswordSuccess')" />
      <MessageStrip cypressId="forgotPassword-messageError" stripType="Error" :visible="showError" :stripText="$t('login.forgotPasswordError')" />
    </div>
  </div>
</template>

<script>
import MessageStrip from "../components/MessageStrip.vue";
import EmailInput from "../components/EmailInput.vue";

export default {
    name: "ForgotPassword",
    components: {
        MessageStrip,
        EmailInput,
    },
    data() {
        return {
            form: {
                email: "",
            },
            emailValueState: "None",
            showSuccess: false,
            showError: false,
            isSetPasswordEnabled: false,
        };
    },
    methods: {
        async handleRequestNewPassword() {
            const busyIndicator = document.getElementById("busyIndicator");
            busyIndicator.active = true;
            if (this.emailValueState === "Error" || !this.isSetPasswordEnabled) {
                return;
            }
            const sLangUpperCase = this.$i18n.locale.toUpperCase();
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: this.form.email,
                    lang: sLangUpperCase,
                }),
            };
            await fetch(`${this.$apiGateway}/auth/user/resetPassword`, options).then(() => {
                this.showSuccess = true;
                this.showError = false;
                busyIndicator.active = false;
                this.isSetPasswordEnabled = false;
            }).catch(() => {
                this.showSuccess = false;
                this.showError = true;
                busyIndicator.active = false;
            });
        },
        handleValidateEmail(sEmailValueState) {
            this.emailValueState = sEmailValueState;
            if ((sEmailValueState !== "Error") && (this.form.email !== "")) {
                this.isSetPasswordEnabled = true;
            } else {
                this.isSetPasswordEnabled = false;
            }
        },
        handleSetEmail(sEmailValue) {
            this.form.email = sEmailValue;
        },
    },
};
</script>

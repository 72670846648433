<template>
  <div class="locale-changer">
    <ui5-select :value="$i18n.locale" @change="onSelectLanguage" :data-cypress="cypressId">
      <ui5-option :data-cypress="cypressId + '_' + lang" v-for="lang in langs" :key="lang" :value="lang" :selected="lang === $i18n.locale">{{ $t("language." + lang) }}</ui5-option>
    </ui5-select>
  </div>
</template>

<script>
import "@ui5/webcomponents/dist/Select";
import "@ui5/webcomponents/dist/Option";
import "@ui5/webcomponents/dist/Label";

export default {
    name: "locale-changer",
    data() {
        return {
            langs: ["en", "de"],
        };
    },
    props: {
        cypressId: {
            type: String,
            required: false,
            default: "",
        },
    },
    methods: {
        onSelectLanguage(oEvent) {
            this.$i18n.locale = oEvent.target.selectedOption.value;
        },
    },
};
</script>

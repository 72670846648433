<template>
  <form @submit.prevent="handleSetPassword">
    <ui5-label for="loginEmail">{{ $t("login.email") }}</ui5-label>
    <ui5-input :data-cypress="cypressIdEmail" readonly type="Email" name="email" id="loginEmail" maxlength="150" :placeholder="$t('login.email')" :value="form.email" />
    <ui5-label for="loginPassword">{{ $t("login.password") }}</ui5-label>
    <ui5-input :data-cypress="cypressIdPassword" @click="handleClickPasswordField" ref="passwordField" @input="handlePasswordValidation" type="Password" name="password" id="loginPassword" :placeholder="$t('login.password')" :value="form.password" :value-state="passwordValidation.valueState">
      <ui5-icon id="showPWIcon" name="show" slot="icon" />
      <div slot="valueStateMessage">{{ passwordValidation.valueStateMessage }}</div>
    </ui5-input>
    <ui5-label for="confirmPassword">{{ $t("login.confirmPassword") }}</ui5-label>
    <ui5-input :data-cypress="cypressIdPasswordConfirm" @input="handlePasswordRepeatValidation" type="Password" name="password" id="confirmPassword" readonly :placeholder="$t('login.confirmPassword')" :value="form.confirmPassword" :value-state="passwordValidation.valueStateRepeat">
      <div slot="valueStateMessage">{{ passwordValidation.valueStateMessageRepeat }}</div>
    </ui5-input>
    <div class="button">
      <ui5-busy-indicator id="busyIndicator" size="Medium" />
      <ui5-button :data-cypress="cypressIdButton" :disabled="!isSetPasswordEnabled" v-if="!showErrorInvalidToken" design="Default" @click="handleSetPassword">
        {{ buttonName }}
      </ui5-button>
    </div>
  </form>
  <div>
    <MessageStrip stripType="Error" :visible="showErrorInvalidToken" :stripText="$t('login.tokenError')" />
    <MessageStrip stripType="Error" :visible="showErrorInvalidData" :stripText="textErrorInvalidData" />
  </div>
  <PasswordRequirementsPopup ref="passwordGuidelinesParent" :passwordGuidelines="passwordGuidelines" :passwordGuidelinesIcons="passwordGuidelinesIcons" />
</template>

<script>
import MessageStrip from "./MessageStrip.vue";
import PasswordRequirementsPopup from "./PasswordRequirements.vue";
import "@ui5/webcomponents/dist/Popup";

export default {
    name: "PasswordForm",
    components: {
        MessageStrip,
        PasswordRequirementsPopup,
    },
    data() {
        return {
            form: {},
            isSetPasswordEnabled: false,
            passwordGuidelines: {
                minimumTotal: 8,
                minimumNumber: 1,
                minimumLowercase: 1,
                minimumUppercase: 1,
                minimumSpecial: 1,
            },
            showErrorInvalidToken: false,
            showErrorInvalidData: false,
            textErrorInvalidData: "",
            passwordGuidelinesIcons: {
                showCheckIconMin8Signs: false,
                showCheckIconMin1UpperCase: false,
                showCheckIconMin1Number: false,
                showCheckIconMin1SmallCase: false,
                showCheckIconMin1NonWord: false,
                showDeleteIconMin8Signs: true,
                showDeleteIconMin1UpperCase: true,
                showDeleteIconMin1Number: true,
                showDeleteIconMin1SmallCase: true,
                showDeleteIconMin1NonWord: true,
            },
            passwordValidation: {
                valueState: "None",
                valueStateRepeat: "None",
                valueStateMessage: "",
                valueStateMessageRepeat: "",
            },
        };
    },
    props: {
        buttonName: {
            type: String,
            required: false,
            default: "",
        },
        cypressIdEmail: {
            type: String,
            required: false,
            default: "",
        },
        cypressIdPassword: {
            type: String,
            required: false,
            default: "",
        },
        cypressIdPasswordConfirm: {
            type: String,
            required: false,
            default: "",
        },
        cypressIdButton: {
            type: String,
            required: false,
            default: "",
        },
        url: {
            type: String,
            required: false,
            default: "",
        },
        urlPost: {
            type: String,
            required: false,
            default: "",
        },
    },
    created() {
        fetch(`${this.$apiGateway}/auth/user/${this.url}`, {
            headers: {
                Authorization: `Bearer ${this.$route.params.token}`,
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 401) {
                    this.showErrorInvalidToken = true;
                } else {
                    this.form = { ...data };
                    this.form.token = this.$route.params.token;
                    this.showErrorInvalidToken = false;
                    if (data.passwordGuidelines && data.passwordGuidelines.exists) {
                        this.passwordGuidelines = { ...data.passwordGuidelines };
                    }
                }
            }).catch((err) => {
                this.errors = err;
                this.showErrorInvalidToken = true;
            });
    },
    mounted() {
        setTimeout(() => document.querySelector("#showPWIcon").addEventListener("click", () => {
            const confirmPassword = document.getElementById("confirmPassword");
            if (document.getElementById("loginPassword").getAttribute("type") === "Password") {
                document.getElementById("loginPassword").setAttribute("type", "Text");
                confirmPassword?.setAttribute("type", "Text");
                document.getElementById("showPWIcon").setAttribute("name", "hide");
            } else {
                document.getElementById("loginPassword").setAttribute("type", "Password");
                confirmPassword?.setAttribute("type", "Password");
                document.getElementById("showPWIcon").setAttribute("name", "show");
            }
        }), 0);
    },
    methods: {
        async handleSetPassword() {
            if (!this.isSetPasswordEnabled) {
                return;
            }
            try {
                const options = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${this.$route.params.token}`,
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ...this.form }),
                };
                fetch(`${this.$apiGateway}/auth/user/${this.urlPost}`, options).then((response) => response.json()).then((data) => {
                    if (data.statusCode === 500) {
                        this.textErrorInvalidData = this.$t("login.credentialsError");
                        this.showErrorInvalidData = true;
                    } else if (data.statusCode === 401 || data.statusCode === 400) {
                        this.textErrorInvalidData = this.$t("login.credentialsError");
                        if (data.message[0] && data.message[0].constraints) {
                            this.textErrorInvalidData = this.$t(`login.error_${Object.keys(data.message[0].constraints)[0]}`);
                        }
                        this.showErrorInvalidData = true;
                    } else {
                        this.showErrorInvalidData = false;
                        this.textErrorInvalidData = "";
                        this.$router.push("/");
                    }
                });
            } catch (err) {
                this.textErrorInvalidData = this.$t("login.credentialsError");
                if (err.response.data.message[0] && err.response.data.message[0].constraints) {
                    this.textErrorInvalidData = this.$t(`login.error_${Object.keys(err.response.data.message[0].constraints)[0]}`);
                }
                this.showErrorInvalidData = true;
            }
        },
        handlePasswordValidation(oEvent) {
            this.passwordValidation.valueState = "Error";
            this.passwordValidation.valueStateMessage = this.$t("login.passwordValidation.guidelinesNotMatched");

            this.form.password = oEvent.target.id === "loginPassword" ? oEvent.target.value : this.form.password;
            this.form.confirmPassword = oEvent.target.id === "confirmPassword" ? oEvent.target.value : this.form.confirmPassword;
            const sPassword = oEvent.target.value;
            this.passwordGuidelinesIcons.showCheckIconMin8Signs = sPassword.length >= this.passwordGuidelines.minimumTotal;
            this.passwordGuidelinesIcons.showDeleteIconMin8Signs = !(sPassword.length >= this.passwordGuidelines.minimumTotal);
            this.passwordGuidelinesIcons.showCheckIconMin1Number = (sPassword.match(/[0-9]/g) || []).length >= this.passwordGuidelines.minimumNumber;
            this.passwordGuidelinesIcons.showDeleteIconMin1Number = !((sPassword.match(/[0-9]/g) || []).length >= this.passwordGuidelines.minimumNumber);
            this.passwordGuidelinesIcons.showCheckIconMin1SmallCase = (sPassword.match(/[a-z]/g) || []).length >= this.passwordGuidelines.minimumLowercase;
            this.passwordGuidelinesIcons.showDeleteIconMin1SmallCase = !((sPassword.match(/[a-z]/g) || []).length >= this.passwordGuidelines.minimumLowercase);
            this.passwordGuidelinesIcons.showCheckIconMin1UpperCase = (sPassword.match(/[A-Z]/g) || []).length >= this.passwordGuidelines.minimumUppercase;
            this.passwordGuidelinesIcons.showDeleteIconMin1UpperCase = !((sPassword.match(/[A-Z]/g) || []).length >= this.passwordGuidelines.minimumUppercase);
            this.passwordGuidelinesIcons.showCheckIconMin1NonWord = (sPassword.match(/[-!@#_$§%^&*(),/.?":{}|<>='=+€\\]/g) || []).length >= this.passwordGuidelines.minimumSpecial;
            this.passwordGuidelinesIcons.showDeleteIconMin1NonWord = !((sPassword.match(/[-!@#_$§%^&*(),/.?":{}|<>='=+€\\]/g) || []).length >= this.passwordGuidelines.minimumSpecial);

            const bPasswordGuidelinesMet = this.passwordGuidelinesIcons.showCheckIconMin8Signs && this.passwordGuidelinesIcons.showCheckIconMin1Number
            && this.passwordGuidelinesIcons.showCheckIconMin1SmallCase && this.passwordGuidelinesIcons.showCheckIconMin1UpperCase && this.passwordGuidelinesIcons.showCheckIconMin1NonWord;

            if (bPasswordGuidelinesMet === true) {
                this.passwordValidation.valueState = "Success";
                this.passwordValidation.valueStateMessage = "";
                document.getElementById("confirmPassword").readonly = false;
            } else {
                document.getElementById("confirmPassword").readonly = true;
            }
        },
        handlePasswordRepeatValidation(oEvent) {
            if (oEvent.target.value !== "") {
                document.getElementById("loginPassword").readonly = true;
            } else {
                document.getElementById("loginPassword").readonly = false;
            }
            this.passwordValidation.valueStateRepeat = "Error";
            this.passwordValidation.valueStateMessageRepeat = this.$t("login.passwordValidation.passwordMissmatch");

            this.form.confirmPassword = oEvent.target.id === "confirmPassword" ? oEvent.target.value : this.form.confirmPassword;

            if (this.form.password && this.form.confirmPassword && (this.form.password === this.form.confirmPassword)) {
                this.passwordValidation.valueStateRepeat = "Success";
                this.passwordValidation.valueStateMessageRepeat = "";
                this.isSetPasswordEnabled = true;
            } else {
                this.isSetPasswordEnabled = false;
            }
        },
        handleClickPasswordField() {
            this.$refs.passwordGuidelinesParent.$refs.passwordGuidelines.showAt(this.$refs.passwordField);
            setTimeout(() => {
                document.getElementById("loginPassword").focus();
            }, 0);
        },
    },
};
</script>

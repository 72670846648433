<template>
  <div class="wrapper">
    <div class="form">
      <ui5-title level="H2">{{ $t("changeEmail.changeEmail") }}</ui5-title>
      <MessageStrip data-cypress="changeEmailSucessMessage" stripType="Success" :visible="!showErrorInvalidToken" :stripText="$t('changeEmail.changeEmailSuccess')" />
      <MessageStrip data-cypress="changeEmailFailMessage" stripType="Error" :visible="showErrorInvalidToken" :stripText="$t('changeEmail.changeEmailFail')" />
    </div>
  </div>
</template>

<script>
import MessageStrip from "../components/MessageStrip.vue";

export default {
    name: "ChangeEmail",
    components: {
        MessageStrip,
    },
    data() {
        return {
            showErrorInvalidToken: false,
        };
    },
    created() {
        fetch(`${this.$apiGateway}/auth/user/setEmail`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${this.$route.params.token}`,
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.statusCode >= 400) {
                    this.showErrorInvalidToken = true;
                } else {
                    this.showErrorInvalidToken = false;
                }
            }).catch(() => {
                this.showErrorInvalidToken = true;
            });
    },
};
</script>

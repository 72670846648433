import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import Imprint from "../views/Imprint.vue";
import Policy from "../views/Policy.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ChangeEmail from "../views/ChangeEmail.vue";
import SetInitialPassword from "../views/SetInitialPassword.vue";
import Register from "../views/Register.vue";

const routes = [
    {
        path: "/:loginId?",
        name: "Login",
        component: Login,
    },
    {
        path: "/imprint",
        name: "Imprint",
        component: Imprint,
    },
    {
        path: "/policy",
        name: "Policy",
        component: Policy,
    },
    {
        path: "/forgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/changePassword/:token",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/changeEmail/:token",
        name: "ChangeEmail",
        component: ChangeEmail,
    },
    {
        path: "/setInitialPassword/:token",
        name: "SetInitialPassword",
        component: SetInitialPassword,
    },
    {
        path: "/register/:token",
        name: "Register",
        component: Register,
        alias: ["/registerUser/:token", "/registerCompany/:token"],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : "myleo / dsc";
    if (to.matched.length < 1) {
        next(false);
        router.push("/");
    } else {
        next();
    }
});

export default router;

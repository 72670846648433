import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";

import en from "./lang/en.json";
import de from "./lang/de.json";

const i18n = createI18n({
    locale: "en",
    warnHtmlInMessage: "off",
    messages: {
        en,
        de,
    },
});

const app = createApp(App);
app.config.globalProperties.$apiGateway = window.apiGateway || "https://testing.portal.leogistics.cloud";
app.config.globalProperties.$portalGateway = window.portalGateway || "https://testing.portal.leogistics.cloud";
app.use(router).use(i18n).mount("#app");

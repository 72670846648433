<template>
  <div class="wrapper">
    <div class="form">
      <ui5-title level="H2">{{ $t("register.headline") }}</ui5-title>
      <form @submit.prevent="handleRegisterNewUser" class="login">
        <ui5-label for="firstName" required>{{ $t("register.firstName") }}</ui5-label>
        <ui5-input data-cypress="registerFirstName" @input="handleInputValidation" id="firstname" :placeholder="$t('register.firstName')" :value="newUser.firstname" />
        <ui5-label for="lastName" required>{{ $t("register.lastName") }}</ui5-label>
        <ui5-input data-cypress="registerLastName" @input="handleInputValidation" id="lastname" :placeholder="$t('register.lastName')" :value="newUser.lastname" />
        <ui5-label for="email">{{ $t("register.email") }}</ui5-label>
        <ui5-input data-cypress="registerEmail" readonly type="Email" name="email" id="email" maxlength="150" :placeholder="$t('register.email')" :value="newUser.email" />
        <ui5-label for="password" required>{{ $t("register.password") }}</ui5-label>
        <ui5-input required data-cypress="registerPassword" @click="handleClickPasswordField" ref="passwordField" @input="handlePasswordValidation" type="Password" name="password" id="password" :placeholder="$t('register.password')" :value="newUser.password" :value-state="passwordValidation.valueState">
          <ui5-icon id="showPWIcon" name="show" slot="icon" />
          <div slot="valueStateMessage">{{ passwordValidation.valueStateMessage }}</div>
        </ui5-input>
        <ui5-label for="passwordRepeat" required>{{ $t("register.passwordRepeat") }}</ui5-label>
        <ui5-input required data-cypress="registerPasswordConfirm" @input="handlePasswordRepeatValidation" type="Password" name="password" id="passwordRepeat" readonly :placeholder="$t('register.passwordRepeat')" :value="newUser.passwordRepeat" :value-state="passwordValidation.valueStateRepeat">
          <div slot="valueStateMessage">{{ passwordValidation.valueStateMessageRepeat }}</div>
        </ui5-input>
        <ui5-label>{{ $t("register.language") }}</ui5-label>
        <ui5-select :value="$i18n.locale" @change="onSelectLanguage" data-cypress="registerLanguage" id="language">
          <ui5-option v-for="lang in langs" :key="lang" :value="lang">{{ $t("language." + lang) }}</ui5-option>
        </ui5-select>
        <ui5-label>{{ $t("register.displayLanguage") }}</ui5-label>
        <ui5-select :value="$i18n.locale" @change="onSelectLanguage" data-cypress="registerDisplayLanguage" id="displayLanguage">
          <ui5-option v-for="lang in langs" :key="lang" :value="lang">{{ $t("language." + lang) }}</ui5-option>
        </ui5-select>
        <ui5-label for="mobile">{{ $t("register.mobile") }}</ui5-label>
        <ui5-input data-cypress="registerMobile" @input="handleInputValidation" type="Tel" id="mobile" :placeholder="$t('register.mobile')" :value="newUser.mobile" />
        <div class="button">
          <ui5-busy-indicator id="busyIndicator" size="Medium" />
          <ui5-button data-cypress="registerButton" :disabled="!isSetPasswordEnabled" v-if="!showErrorInvalidToken" design="Default" @click="handleRegisterNewUser">
            {{ $t('register.register') }}
          </ui5-button>
        </div>
      </form>
      <div>
        <MessageStrip stripType="Error" :visible="showErrorInvalidToken" :stripText="$t('register.tokenError')" />
        <MessageStrip stripType="Error" :visible="showErrorInvalidData" :stripText="textErrorInvalidData" />
      </div>
    </div>
  </div>
  <PasswordRequirementsPopup ref="passwordGuidelinesParent" :passwordGuidelines="passwordGuidelines" :passwordGuidelinesIcons="passwordGuidelinesIcons" />
</template>

<script>
import "@ui5/webcomponents/dist/Button";
import "@ui5/webcomponents/dist/Input";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport";
import "@ui5/webcomponents/dist/Label";
import "@ui5/webcomponents/dist/Title";
import "@ui5/webcomponents/dist/Select";
import "@ui5/webcomponents/dist/Option";

import MessageStrip from "../components/MessageStrip.vue";
import PasswordRequirementsPopup from "../components/PasswordRequirements.vue";

export default {
    name: "RegisterForm",
    components: {
        MessageStrip,
        PasswordRequirementsPopup,
    },
    data() {
        return {
            newUser: {
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                passwordRepeat: "",
                language: "",
                displayLanguage: "",
                mobile: "",
            },
            isSetPasswordEnabled: false,
            passwordGuidelines: {
                minimumTotal: 8,
                minimumNumber: 1,
                minimumLowercase: 1,
                minimumUppercase: 1,
                minimumSpecial: 1,
            },
            passwordGuidelinesIcons: {
                showCheckIconMin8Signs: false,
                showCheckIconMin1UpperCase: false,
                showCheckIconMin1Number: false,
                showCheckIconMin1SmallCase: false,
                showCheckIconMin1NonWord: false,
                showDeleteIconMin8Signs: true,
                showDeleteIconMin1UpperCase: true,
                showDeleteIconMin1Number: true,
                showDeleteIconMin1SmallCase: true,
                showDeleteIconMin1NonWord: true,
            },
            passwordValidation: {
                valueState: "None",
                valueStateRepeat: "None",
                valueStateMessage: "",
                valueStateMessageRepeat: "",
            },
            langs: ["empty", "de", "en", "en_US", "en_GB", "pl", "cs", "mk"],
            showErrorInvalidToken: false,
            showErrorInvalidData: false,
            textErrorInvalidData: "",
        };
    },
    created() {
        fetch(`${this.$apiGateway}/auth/user/getInvitation`, {
            headers: {
                Authorization: `Bearer ${this.$route.params.token}`,
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.statusCode === 401) {
                    this.showErrorInvalidToken = true;
                } else {
                    this.newUser.email = data.email;
                    this.newUser.token = this.$route.params.token;
                    this.showErrorInvalidToken = false;
                }
            }).catch((err) => {
                this.errors = err;
                this.showErrorInvalidToken = true;
            });
    },
    mounted() {
        setTimeout(() => document.querySelector("#showPWIcon").addEventListener("click", () => {
            const passwordRepeat = document.getElementById("passwordRepeat");
            if (document.getElementById("password").getAttribute("type") === "Password") {
                document.getElementById("password").setAttribute("type", "Text");
                passwordRepeat?.setAttribute("type", "Text");
                document.getElementById("showPWIcon").setAttribute("name", "hide");
            } else {
                document.getElementById("password").setAttribute("type", "Password");
                passwordRepeat?.setAttribute("type", "Password");
                document.getElementById("showPWIcon").setAttribute("name", "show");
            }
        }), 0);
        this.newUser.firstname = localStorage.getItem("firstname");
        this.newUser.lastname = localStorage.getItem("lastname");
        this.newUser.mobile = localStorage.getItem("mobile");
    },
    methods: {
        onSelectLanguage(oEvent) {
            this.newUser[oEvent.target.id] = oEvent.target.selectedOption.value;
        },
        handleInputValidation(oEvent) {
            this.newUser[oEvent.target.id] = oEvent.target.value;
            localStorage.setItem(oEvent.target.id, oEvent.target.value);
            localStorage.setItem("password", "");
            localStorage.setItem("passwordRepeat", "");
        },
        async handleRegisterNewUser() {
            const busyIndicator = document.getElementById("busyIndicator");
            busyIndicator.active = true;
            if (!this.isSetPasswordEnabled) {
                return;
            }
            this.newUser.language = this.newUser.language === "empty" ? "" : this.newUser.language;
            this.newUser.displayLanguage = this.newUser.displayLanguage === "empty" ? "" : this.newUser.displayLanguage;
            try {
                const options = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${this.$route.params.token}`,
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ...this.newUser }),
                };
                fetch(`${this.$apiGateway}/auth/user/registerAfterInvitation/registerAnonymousProfile`, options).then((response) => response.json()).then((data) => {
                    if (data.statusCode === 500) {
                        this.textErrorInvalidData = this.$t("register.tokenError");
                        this.showErrorInvalidData = true;
                    } else if (data.statusCode === 401 || data.statusCode === 400) {
                        this.textErrorInvalidData = this.$t("register.tokenError");
                        if (data.message[0] && data.message[0].constraints) {
                            this.textErrorInvalidData = this.$t(`register.error_${Object.keys(data.message[0].constraints)[0]}`);
                        }
                        this.showErrorInvalidData = true;
                    } else {
                        this.showErrorInvalidData = false;
                        this.textErrorInvalidData = "";
                        this.$router.push("/");
                    }
                    busyIndicator.active = false;
                });
            } catch (err) {
                this.textErrorInvalidData = this.$t("register.tokenError");
                if (err.response.data.message[0] && err.response.data.message[0].constraints) {
                    this.textErrorInvalidData = this.$t(`register.error_${Object.keys(err.response.data.message[0].constraints)[0]}`);
                }
                this.showErrorInvalidData = true;
                busyIndicator.active = false;
            }
        },
        handlePasswordValidation(oEvent) {
            this.passwordValidation.valueState = "Error";
            this.passwordValidation.valueStateMessage = this.$t("login.passwordValidation.guidelinesNotMatched");

            this.newUser.password = oEvent.target.id === "password" ? oEvent.target.value : this.newUser.password;
            const sPassword = oEvent.target.value;
            this.passwordGuidelinesIcons.showCheckIconMin8Signs = sPassword.length >= this.passwordGuidelines.minimumTotal;
            this.passwordGuidelinesIcons.showDeleteIconMin8Signs = !(sPassword.length >= this.passwordGuidelines.minimumTotal);
            this.passwordGuidelinesIcons.showCheckIconMin1Number = (sPassword.match(/[0-9]/g) || []).length >= this.passwordGuidelines.minimumNumber;
            this.passwordGuidelinesIcons.showDeleteIconMin1Number = !((sPassword.match(/[0-9]/g) || []).length >= this.passwordGuidelines.minimumNumber);
            this.passwordGuidelinesIcons.showCheckIconMin1SmallCase = (sPassword.match(/[a-z]/g) || []).length >= this.passwordGuidelines.minimumLowercase;
            this.passwordGuidelinesIcons.showDeleteIconMin1SmallCase = !((sPassword.match(/[a-z]/g) || []).length >= this.passwordGuidelines.minimumLowercase);
            this.passwordGuidelinesIcons.showCheckIconMin1UpperCase = (sPassword.match(/[A-Z]/g) || []).length >= this.passwordGuidelines.minimumUppercase;
            this.passwordGuidelinesIcons.showDeleteIconMin1UpperCase = !((sPassword.match(/[A-Z]/g) || []).length >= this.passwordGuidelines.minimumUppercase);
            this.passwordGuidelinesIcons.showCheckIconMin1NonWord = (sPassword.match(/[-!@#_$§%^&*(),/.?":{}|<>='=+€\\]/g) || []).length >= this.passwordGuidelines.minimumSpecial;
            this.passwordGuidelinesIcons.showDeleteIconMin1NonWord = !((sPassword.match(/[-!@#_$§%^&*(),/.?":{}|<>='=+€\\]/g) || []).length >= this.passwordGuidelines.minimumSpecial);

            const bPasswordGuidelinesMet = this.passwordGuidelinesIcons.showCheckIconMin8Signs && this.passwordGuidelinesIcons.showCheckIconMin1Number
            && this.passwordGuidelinesIcons.showCheckIconMin1SmallCase && this.passwordGuidelinesIcons.showCheckIconMin1UpperCase && this.passwordGuidelinesIcons.showCheckIconMin1NonWord;

            if (bPasswordGuidelinesMet === true) {
                this.passwordValidation.valueState = "Success";
                this.passwordValidation.valueStateMessage = "";
                document.getElementById("passwordRepeat").readonly = false;
            }
        },
        handlePasswordRepeatValidation(oEvent) {
            if (oEvent.target.value !== "") {
                document.getElementById("password").readonly = true;
            } else {
                document.getElementById("password").readonly = false;
            }
            this.passwordValidation.valueStateRepeat = "Error";
            this.passwordValidation.valueStateMessageRepeat = this.$t("login.passwordValidation.passwordMissmatch");

            this.newUser.passwordRepeat = oEvent.target.id === "passwordRepeat" ? oEvent.target.value : this.newUser.passwordRepeat;

            if (this.newUser.password && this.newUser.passwordRepeat && (this.newUser.password === this.newUser.passwordRepeat)) {
                this.passwordValidation.valueStateRepeat = "Success";
                this.passwordValidation.valueStateMessageRepeat = "";
                this.isSetPasswordEnabled = true;
            }
        },
        handleClickPasswordField() {
            this.$refs.passwordGuidelinesParent.$refs.passwordGuidelines.showAt(this.$refs.passwordField);
            setTimeout(() => {
                document.getElementById("password").focus();
            }, 0);
        },
    },
};
</script>

<template>
  <div class="text">
    <div v-if="$i18n.locale === `en`">
      <iframe title="imprintEN" style="border:transparent;width:100%;height:3000px;" src="../static/en/imprint.html" />
    </div>
    <div v-if="$i18n.locale === `de`">
      <iframe title="imprintDE" style="border:transparent;width:100%;height:3000px;" src="../static/de/imprint.html" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "Policy",
};
</script>
